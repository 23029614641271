// 端口配置文件
const port = 80;
const BASEURL = 'https://app.baijiawanan.com/apiylxb/';

const API_LIST = {
  // 查看套餐
  selelct_order : BASEURL+'Order/selelct_order',
  // 核验
  check_order : BASEURL+'Order/check_order',
  // 查找历史纪录
  history_prev_day3: BASEURL+'Order/history_prev_day3',
  // 当餐核验记录
  examine_list: BASEURL+'Order/examine_list',
}

module.exports = API_LIST;
